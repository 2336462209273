* {
  font-family: "Inter Tight", sans-serif;
  color: #020026;
  --swiper-theme-color: #020026 !important;
  --swiper-pagination-bullet-inactive-color: transparent;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

::-webkit-scrollbar {
  width: 12px;
  height: 18px;
}
::-webkit-scrollbar-thumb {
  min-height: 30px;
  border: 4px solid transparent;
  background-clip: padding-box;
  background-color: #020026;
}
::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}

body {
  margin: 0;
  background-color: #f8f8fd;
}

/* header and such */

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 40px;
  box-sizing: border-box;
  background: #f8f8fd;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
}

.header-links,
.header-name {
  flex: 1;
}

.header-links {
  display: flex;
  justify-content: right;
}

.header-name {
  font-size: 28px;
}

header a {
  text-decoration: none;
  font-size: 20px;
  padding: 10px 20px;
  cursor: pointer;
}

/* mobile header */

#mobile-menubutton {
  display: none;
  padding: 10px;
  z-index: 5;
  position: absolute;
  right: 10px;
}

#mobile-menubutton svg,
#ml_close {
  width: 24px;
  height: 24px;
}

#ml_close {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

#mobile-links {
  display: none;
  padding: 60px 40px 0 40px;
  box-sizing: border-box;
}

#mobile-links.active {
  display: block;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #f8f8fd;
}

.section {
  min-height: calc(100vh - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

#artworks {
  margin-top: 104px;
  min-height: calc(100vh - 144px);
}

.about-content {
  font-size: 20px;
  line-height: 1.5;
}

#contact {
  flex-direction: column;
}

.contact-email {
  text-align: center;
}

.contact-email-label {
  font-size: 20px;
  margin-bottom: 5px;
}

.contact-email-address {
  font-size: 24px;
}

.contact-email-address a {
  text-decoration: none;
}

.contact-bar {
  width: 15px;
  height: 2px;
  margin: 40px auto;
  background: #020026;
}

.contact-links a {
  padding: 15px;
}

.contact-links a img {
  width: 24px;
  height: 24px;
}

.scrolltotop {
  display: flex;
  cursor: pointer;
  padding: 10px;
  align-items: center;
  margin-top: 25px;
}

.scrolltotop svg {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  position: relative;
  top: 0;
  transition: top 0.2s;
}

.scrolltotop:hover svg {
  top: -5px;
}

.credits {
  font-size: 12px;
  text-align: center;
  padding-bottom: 20px;
}

.credits p {
  margin: 5px;
}

.carousel {
  max-width: 1200px;
  margin: 0 auto;
}

.image {
  margin: 0 auto;
  text-align: center;
  max-width: calc(100% - 160px);
  width: fit-content;
  cursor: default;
}

.image img {
  max-height: calc(100vh - 220px);
  max-width: 100%;
  box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.25);
}

.swiper-pagination-bullet {
  border: 1px solid var(--swiper-theme-color);
}

.image-text {
  text-align: left;
  align-self: flex-start;
  margin-top: 10px;
}

.text-title {
  font-size: 20px;
}

.text-title span {
  opacity: 0.7;
}

.swiper-wrapper {
  align-items: center;
}

@media screen and (max-width: 600px) {
  #artworks {
    margin-top: 64px;
    min-height: calc(100vh - 104px);
    align-items: center;
  }
  header {
    padding: 20px;
  }
  .header-name {
    font-size: 24px;
  }
  header .header-links {
    display: none;
  }
  header #mobile-menubutton {
    display: inline-block;
    cursor: pointer;
  }
  #mobile-links a {
    margin-bottom: 15px;
    display: block;
  }
  .about-content {
    font-size: 16px;
  }
  .image {
    max-width: 100%;
  }
  .carousel {
    position: absolute;
    width: 100%;
  }
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }
}
